// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-products-10-wifi-js": () => import("./../../../src/pages/products/10wifi.js" /* webpackChunkName: "component---src-pages-products-10-wifi-js" */),
  "component---src-pages-products-7-wifi-js": () => import("./../../../src/pages/products/7wifi.js" /* webpackChunkName: "component---src-pages-products-7-wifi-js" */),
  "component---src-pages-products-73-g-js": () => import("./../../../src/pages/products/73g.js" /* webpackChunkName: "component---src-pages-products-73-g-js" */),
  "component---src-pages-products-gc-176-js": () => import("./../../../src/pages/products/gc176.js" /* webpackChunkName: "component---src-pages-products-gc-176-js" */),
  "component---src-pages-products-hub-js": () => import("./../../../src/pages/products/hub.js" /* webpackChunkName: "component---src-pages-products-hub-js" */),
  "component---src-pages-products-index-js": () => import("./../../../src/pages/products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-pages-products-kids-js": () => import("./../../../src/pages/products/kids.js" /* webpackChunkName: "component---src-pages-products-kids-js" */),
  "component---src-pages-products-spot-js": () => import("./../../../src/pages/products/spot.js" /* webpackChunkName: "component---src-pages-products-spot-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

