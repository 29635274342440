module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"GlobalConnect","short_name":"GC","start_url":"/","background_color":"grey","theme_color":"#663399","display":"minimal-ui","icon":"src/images/gc_gc.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"a35054b27c9023887c98a48d92d31580"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
